import React from 'react';
import './AtelierDetail.scss';
import {useNavigate, useNavigation, useParams} from "react-router-dom";
import useFecthSolo from "../../hooks/useFecthSolo";
// @ts-ignore
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Loader from "../../components/loader";
import {GoBack} from "../../components/go-back/GoBack";
import Image from "../../models/Image";
import Atelier from "../../models/Atelier";
import LineWithBr from "../../components/line_with_br";
import "./AtelierDetail.scss"
import Artiste from "../../models/Artiste";

export const AtelierDetail = () => {
    const { id } = useParams();
    const { data, loading, error } = useFecthSolo<Atelier>('/ateliers/' + id);

    const navigate = useNavigate();

    const navigateToArtiste = (id: number) => {
        navigate('/artiste/' + id)
    }

    const formatArtiste = (artistes: Artiste[]) => {
        const artisteElements = artistes.map((artiste, index) =>
            <span key={artiste.id}>
            {index > 0 && ', '}
                <span className={"text-url"} onClick={() => navigateToArtiste(artiste.id)}>{artiste.prenom + " " + artiste.nom}</span>
        </span>
        );
        return (
          <>
              , par {artisteElements}
          </>
        );
    };



    return (
        <div className={"atelier-container-detail"}>
            {loading && <Loader />}
            {error && <p>Erreur lors du chargement des données</p>}
            {data &&
                <>
                    <GoBack/>
                    <div className={"atelier-container-flex"}>
                    <div className={"atelier-detail"}>
                        <h1> {data.titre}
                            {formatArtiste(data.artistes)}</h1>
                        <LineWithBr>{data.description}</LineWithBr>
                        <LineWithBr>{data.information}</LineWithBr>
                    </div>

                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    {data.imageAteliers.length > 0 &&
                        <ImageGallery
                            className={"image-gallery"}
                            thumbnailPosition={"bottom"}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            slideDuration={200}
                            showIndex={true}
                            items={data.imageAteliers.map((image: Image) => (
                                {
                                    original: `data:${image.imageContentType};base64,${image.image}`,
                                    thumbnail: `data:${image.imageContentType};base64,${image.image}`,
                                }
                            ))}/>}
                    </div>
                </>
            }
        </div>
    );
};