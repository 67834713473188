import React from 'react';
import './Header.scss';
import {useNavigate} from "react-router-dom";

export const Header = () => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
  }

  return (
<header className={"header"}>
  <img className={"header-amb"} src={"/amb-header.png"} alt={"header-amb"}/>
  <img className={"logo-amb-fixed"} src={"/logo-amb.png"} alt={"logo-amb"} onClick={() => {handleNavigation("/")}}/>
  <div className={"bloc"}>
    <ul>
      <li><a onClick={() => {
        handleNavigation("/")
      }}>Accueil</a></li>
      <li><a onClick={() => {
        handleNavigation("compagnie")
      }}>La Compagnie</a></li>

      <li><a onClick={() => {
        handleNavigation("spectacles")
      }}>Spectacles</a></li>
      <li><a onClick={() => {
        handleNavigation("ateliers")
      }}>Ateliers</a></li>

      <li><a onClick={() => {
        handleNavigation("art-et-soin")
      }}>Art et Soin</a></li>
      <li><a onClick={() => {
        handleNavigation("boutons-d-art")
      }}>Boutons d'Art</a></li>

      <li><a onClick={() => {
        handleNavigation("et-aussi")
      }}>ET AUSSI</a></li>

      <li><a onClick={() => {
        handleNavigation("presse")
      }}>Presse</a></li>
    </ul>
  </div>
</header>);
};