import React from "react";
import Card from "../../components/card";
import "./Spectacle.scss";
import SpectacleCategory from "../../models/SpectacleCategory";
import spectacleCategories from "../../data/SpectacleCategories";
import {BaCard} from "../../components/ba-card/BaCard";
import {useNavigate} from "react-router-dom";
import useFetch from "../../hooks/useFecth";
import ImageCategorySpectacle from "../../models/ImageCategorySpectacle";
import Loader from "../../components/loader";

export const Spectacle = () => {

  const navigate = useNavigate();

  const { data, loading, error } = useFetch<ImageCategorySpectacle>('/spectacles-categories/');

  function handleClick(category: SpectacleCategory) {
    navigate(`/spectacles/categorie/${category.enumName}`)
  }

  function getPhotoByName(name: string) {
    console.log(name);
    return data?.find((imageCategorySpectacle: ImageCategorySpectacle) => imageCategorySpectacle.name === name)?.image;
  }



  return (<>
    {loading && <Loader />}
  {error && <p>Erreur lors du chargement des données</p>}
  {
    data &&
    <>
      <div className="spectacle-all-container">
        <h1 className={"title"}>Découvrez nos spectacles pour petits et grands</h1>

        <div className="information">
          <p>Les artistes de la compagnie Allo Maman Bobo disposent d'un large répertoire théâtre, conte, marionnettes,
            chansons, lectures... Ils peuvent, selon votre demande, vous proposer une création sur mesure ou reprendre
            l'un de leurs spectacles qui n'est plus en tournée actuellement.</p>
        </div>
        <div className="card-grid">
          {spectacleCategories.map((category: SpectacleCategory) => (
            <BaCard
              key={category.id}
              title={category.category}
              image={getPhotoByName(category.enumName)}
              imageContentType={"image/jpeg"}
              onClick={() => handleClick(category)}
            />
          ))}
        </div>
        <div className="demandes">
          <p>
            Pour toute demande particulière, merci de contacter
            Sophie Dubosc<br/>
            <a href="tel:+33766719772">07 66 71 97 72</a> / <a
            href="mailto:allomamanbobo.production@gmail.com">allomamanbobo.production@gmail.com</a>
          </p>
        </div>
      </div>
    </>
  }
  </>
  );
};