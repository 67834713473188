import useFetch from "../../hooks/useFecth";
import "./BoutonDart.scss";
import {useNavigate} from "react-router-dom";
import BoutonDart from "../../models/BoutonDart";
import Loader from "../../components/loader";
import Card from "../../components/card";
import React from "react";

export const BoutonDartPage = () => {

  const { data, loading, error } = useFetch<BoutonDart>('/boutondart');
  const navigate = useNavigate();


  const handleClick = (id: number) => {
    let path = `/boutons-d-art/${id}`;
    navigate(path);
  }

  return (
    <>
      {loading && <Loader/>}
      {error && <p>Erreur lors du chargement des données</p>}
      {data &&

      <div className={"ba-container-global"}>
        <h1 className={"title"}>Le festival Boutons d'Art</h1>
        <p className={"information"}>Chaque année au mois de septembre, la compagnie Allo Maman Bobo a le plaisir d’organiser son festival annuel, « Boutons d’Art ». Ce festival se déroule dans le quartier des Blossières à Orléans ; les spectacles et animations ont lieu dans les jardins privés du quartier.<br/>
          Au programme : musique, danse, marionnettes, contes,... pour petits et grands.</p>
        <div className={"ba-container"}>
          {data.map((boutonDart: BoutonDart) => (

            <Card
              id={boutonDart.id}
              title={boutonDart.titre}
              image={boutonDart.image}
              description={boutonDart.description}
              dateCreation={""}
              imageContentType={"image/jpeg"}
              path={"/boutons-d-art/"}
            />
          ))}
          {data.map((_: BoutonDart) => (
            <div></div>
          ))}
        </div>
      </div>
        }
    </>
  );
};