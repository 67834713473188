import React from 'react';
import './LaCompagnie.scss';
import useFetch from "../../hooks/useFecth";
import {BaCard} from "../../components/ba-card/BaCard";
import {useNavigate} from "react-router-dom";
import Artiste from "../../models/Artiste";
import useFecthSolo from "../../hooks/useFecthSolo";
import Image from "../../models/Image";

export const LaCompagnie = () => {

  const { data, loading, error } = useFetch<Artiste>('/artistes');
  const { data: dataImage, loading: loadingImage, error: errorImage } = useFecthSolo<Image>('/photo-la-compagnie/');

  const navigate = useNavigate();


  const handleClick = (id: number) => {
    let path = `/artiste/${id}`;
    navigate(path);
  }

  const artisteSort = (a: Artiste, b: Artiste) => {
    if (a.nom === "Le Garff") {
      return -1;
    }
    if (b.nom === "Le Garff") {
      return 1;
    }
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  }

  return (
    <div className={"la-compagnie"}>
      <div className={"lc-image-container"}>
        {loadingImage && <p>Chargement...</p>}
        {errorImage && <p>Erreur lors du chargement des données</p>}
        {dataImage &&
        <img src={`data:${dataImage.imageContentType};base64,${dataImage.image}`} alt="" className={"la-compagnie-banniere"}/>
        }
        </div>
      <div className={"lc-info"}>
        <h1 className={"lc-info-title"}>Allo Maman Bobo est une compagnie professionnelle d'Orléans</h1>
        <p className={"lc-info-quote"}>"Donner ou redonner le sourire, développer l’imaginaire, penser humanisme et environnement, accepter ses émotions, savourer, humer, rencontrer… Voici le programme de la compagnie Allo Maman Bobo. Marionnettes, théâtre, contes et musique répondront à tous les goûts, tous les âges, tous les publics."</p>
        <p className={"lc-info-bio"}>Créée en 1995, la compagnie Allo Maman Bobo est composée de comédiens, conteurs, musiciens, chanteurs, marionnettistes qui tissent des passerelles entre parole et musique, marionnette et théâtre, mots et conte pour donner à rêver et aider à mieux voir le réel, avec comme valeurs de base, le lien, l’environnement, la transmission, et l’humanisme. Allo Maman Bobo va à la rencontre des publics, là où ils se trouvent.</p>
      </div>
      <h1 className={"lc-title-2"}>Les artistes</h1>
      {loading && <p>Chargement...</p>}
      {error && <p>Erreur lors du chargement des données</p>}
      {data &&

        <div className={"la-compagnie-container"}>
          {data.sort(artisteSort).map((artiste: Artiste) => (
            <BaCard
              key={artiste.id}
              title={artiste.prenom + " " + artiste.nom + " - " + artiste.titre}
              image={artiste.photo}
              onClick={() => handleClick(artiste.id)}
            />
          ))}
          {data.map((_: Artiste) => (
            <div></div>
          ))}
        </div>
      }
    </div>
  );
};