import React, {useEffect} from 'react';
import './BoutonDartDetail.scss';
import {useNavigate, useParams} from "react-router-dom";
import useFecthSolo from "../../hooks/useFecthSolo";
// @ts-ignore
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Loader from "../../components/loader";
import {GoBack} from "../../components/go-back/GoBack";
import Image from "../../models/Image";
import BoutonDart from "../../models/BoutonDart";
import LineWithBr from "../../components/line_with_br";

export const BoutonDartDetail = () => {
  const { id } = useParams();
  const { data, loading, error } = useFecthSolo<BoutonDart>('/boutondart/' + id);

  return (
    <div className={"ba-container-detail"}>
      {loading && <Loader />}
      {error && <p>Erreur lors du chargement des données</p>}
      {data &&
        <>
          <div className={"ba-container-flex"}>

            <h1 className={"title"}>{data.titre}</h1>
            <div className={"description"}>
              <LineWithBr>{data.description}</LineWithBr>
            </div>
          <GoBack />
          </div>

          {/* eslint-disable-next-line react/jsx-no-undef */}
          <ImageGallery
            className={"image-gallery"}
            thumbnailPosition={"left"}
            showFullscreenButton={false}
            showPlayButton={false}
            slideDuration={200}
            showIndex={true}
            items={data.imageBoutonDArts
                .sort((a, b) => a.id - b.id) // Tri par ID croissant
                .map((image: Image) => ({
                  original: `data:${image.imageContentType};base64,${image.image}`,
                  thumbnail: `data:${image.imageContentType};base64,${image.image}`,
                }))
            } />

        </>
      }
    </div>
  );
};