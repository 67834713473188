import {useNavigate} from "react-router-dom";
import useFetch from "../../hooks/useFecth";
import Loader from "../../components/loader";
import {BaCard} from "../../components/ba-card/BaCard";
import React from "react";
import AmbSengage from "../../models/AmbSengage";

export const AmbSengagePage = () => {
  const { data, loading, error } = useFetch<AmbSengage>('/amb-s-engage');
  const navigate = useNavigate();

  function handleClick(ambSengage: AmbSengage) {
    navigate(`/et-aussi/${ambSengage.id}`)
  }

  return (
    <>
      {loading && <Loader/>}
      {error && <p>Erreur lors du chargement des données</p>}
      {data &&

          <div className={"ambsengage-catalogue-container"}>
            <h1 className={"title"}>Et aussi</h1>
            <p className={"information"}>En plus de ses actions à l’hôpital, ses ateliers et ses spectacles, Allo Maman Bobo met en place d’autres actions comme les matinées du conte au musée des Beaux-Arts d’Orléans, les sessions à vos contes à destination des familles le dimanche matin ou encore son partenariat avec le festival Une autre histoire.</p>

            <div className={"ambsengage-container"}>
              {data.map((ambSengage: AmbSengage) => (
                  <BaCard
                      key={ambSengage.id}
                      title={ambSengage.titre}
                      image={ambSengage.logoImage}
                      onClick={() => handleClick(ambSengage)}
                  />
              ))}
              {data.map((_: AmbSengage) => (
                  <div></div>
              ))}
            </div>
          </div>
      }
    </>
  );
};