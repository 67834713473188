import React from 'react';
import './SpectacleDetail.scss';
// @ts-ignore
import ImageGallery from "react-image-gallery";
import {useNavigate, useParams} from "react-router-dom";
import useFecthSolo from "../../hooks/useFecthSolo";
import { Spectacle } from '../../models/Spectacle';
import Loader from "../../components/loader";
import {GoBack} from "../../components/go-back/GoBack";
import LineWithBr from "../../components/line_with_br";
import Image from "../../models/Image";
import Artiste from "../../models/Artiste";
import YoutubeVideo from "../../components/youtube-video";

export const SpectacleDetail = () => {

    const { id } = useParams();
    const { data, loading, error } = useFecthSolo<Spectacle>('/spectacles/' + id);
    const navigate = useNavigate();

    const goToArtiste = (id: number) => {
        navigate(`/artiste/${id}`)
    }

    const formatDuree = (duree: number) => {
        const heures = Math.floor(duree / 60);
        const minutes = duree % 60;
        return `Durée : ${heures}h${minutes < 10 ? "0" : ""}${minutes} environ`;
    }

    const formatCategorie = (categorie: string) => {
        switch (categorie) {
            case "TOUT_PETIT":
                return "Spectacle jeune public et tout-petits de 0 à 6 ans";
            case "DES_QUATRES_ANS":
                return "Spectacle jeune public à partir de 4 ans";
            case "DES_SIX_ANS":
                return "Spectacle jeune public à partir de 6 ans";
            case "DES_DIX_ANS":
                return "Spectacle pour enfant à partir de 10 ans";
            case "EN_COURS":
                return "Spectacle en cours de création";
            case "AUTRES_SPECTACLES":
                return "Sans catégorie définie pour le moment";
        }

    };

    return (
        <div className={"spectacle-container-detail"}>
            {loading && <Loader />}
            {error && <p>Erreur lors du chargement des données</p>}
            {data &&
                <>
                    <GoBack/>

                    <div className={"spectacle-container-flex"}>
                        <div>
                            <img src={`data:${data.photoContentType};base64,${data.photo}`} alt="" className={"spectacle-image"}/>
                        </div>
                        <div className={"flex-center"}>
                            <h1>{data.titre}</h1>
                            <h2>{data.sousTitre}</h2>
                            <p>{formatCategorie(data.categorie)}</p>
                            <p>{formatDuree(data.dureeEnMinute)}</p>
                            <h3>Distribution</h3>
                            {data.artistes.map((artiste: Artiste) => (
                                <span className={"text-url"} onClick={() => goToArtiste(artiste.id)}>{artiste.prenom} {artiste.nom}</span>))
                            }

                            <LineWithBr>{data.description}</LineWithBr>

                            {data.teaser && <>
                                <h3>Teaser du spectacle</h3>
                                <YoutubeVideo url={data.teaser}/>
                            </>}
                        </div>
                    </div>

                    {/* eslint-disable-next-line react/jsx-no-undef */}
                    {data.imageSpectacles.length > 0 &&
                    <ImageGallery
                        className={"image-gallery"}
                        thumbnailPosition={"bottom"}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        slideDuration={200}
                        showIndex={true}
                        items={data.imageSpectacles.map((image: Image) => (
                            {
                                original: `data:${image.imageContentType};base64,${image.image}`,
                                thumbnail: `data:${image.imageContentType};base64,${image.image}`,
                            }
                        ))}/>
                    }
                </>
            }
        </div>
    );
};