import React from 'react';
import './BaCard.scss';

export const BaCard = ({ image, title, onClick, imageContentType } : any) => {
  return (
      <div className="card" onClick={onClick} style={{ backgroundImage: `url(data:${imageContentType};base64,${image})` }}>
        <img className={"feuille-fixed"} src={"/feuille.png"} alt={"logo-amb"}/>

        <h1 className="card-title">{title}</h1>
      </div>
  );
};