import React from 'react';
import './NewsletterInfo.scss';
import Newsletter from "../../models/Newsletter";
import useFecthSolo from "../../hooks/useFecthSolo";
import BoutonDart from "../../models/BoutonDart";
import Loader from "../loader";
import {type} from "os";

type NewsletterProps = {
    id: number;
};

export const NewsletterInfo = ({ id }: NewsletterProps) => {
    const { data, loading, error } = useFecthSolo<Newsletter>('/newsletters/' + id);

    return (
        <div className="newsletter">
            <div className="newsletter-container">
                {loading && <Loader />}
                {error && <p>Erreur lors du chargement des données</p>}
                {data && (
                    <>
                        <div className="newsletter-top">
                            <div className="newsletter-title">
                                {data.title.split(" ").map((word, index) => (
                                    <span key={index} className="words">{word}&nbsp;</span>
                                ))}
                            </div>
                            <div className="newsletter-author">Par {data.artistes}</div>
                            <div className="newsletter-pre">{data.descriptionCourte}</div>
                            <div className="newsletter-representation">{data.representation}</div>
                        </div>
                        <div className="newsletter-bottom">
                            <img
                                src={`data:${data.imageContentType};base64,${data.image}`}
                                alt=""
                                className="newsletter-image"
                            />
                            <div className="newsletter-bottom-text">{data.description}</div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
