import React from 'react';
import './Footer.scss';

export const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="logo">
          <img className= "logo-amb" src="/logo-amb.png" alt="logo" />
        </div>
        <div className="legals">
          <p>Association Allo Maman Bobo</p>
          <p>108 rue de Bourgogne, 45000 Orléans</p>

          <p>07 66 71 97 72 | allomamanbobo.production@gmail.com</p>
          <p className={"fixed"}>SIRET : 42269792000011<br/>
          Numéro APE : 9001Z</p>
      </div>
      </div>
    </footer>
  );
};