import useFetch from "../../hooks/useFecth";
import {useNavigate} from "react-router-dom";
import {BaCard} from "../../components/ba-card/BaCard";
import "./Atelier.scss";
import Atelier from "../../models/Atelier";
import React from "react";
import Loader from "../../components/loader";

export const AtelierPage = () => {

  const { data, loading, error } = useFetch<Atelier>('/ateliers');
  const navigate = useNavigate();


  const handleClick = (id: number) => {
    let path = `/ateliers/${id}`;
    navigate(path);
  }

  return (
      <>
        {loading && <Loader/>}
        {error && <p>Erreur lors du chargement des données</p>}
        {data &&
          <div className={"atelier-all-container"}>
          <h1 className={"title"}>Des ateliers et des stages sur-mesure
          </h1>

          <div className="information">
          <p>
            La compagnie Allo Maman Bobo peut également vous proposer des ateliers et des stages en fonction de votre demande et de vos besoins :<br/>
            - ateliers autour du conte, de la musique, du chant, du clown...<br/>
            - ateliers théâtre<br/>
            - interventions en milieu hospitalier<br/>
            - ateliers de fabrication et de manipulation de marionnettes<br/>
            - lectures à voix-haute<br/>
            - ateliers musique et lecture pour la petite-enfance<br/>
            - art-thérapie<br/>
            - diverses formations<br/>
            - ateliers pour les tout-petits et les enfants de maternelle<br/>
            - et bien d’autres encore...
          </p>
          </div>
            <div className={"atelier-container"}>
              {data.map((atelier: Atelier) => (
                  <BaCard
                      key={atelier.id}
                      title={atelier.titre}
                      image={atelier.logoImage}
                      imageContentType={atelier.logoImageContentType}
                      onClick={() => handleClick(atelier.id)}
                  />
              ))}
              {data.map((_: Atelier) => (
                  <div></div>
              ))}
            </div>
          </div>
        }
      </>
  );
};