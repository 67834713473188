import React from "react";

interface LineWithBrProps {
    children: string;
}

export const LineWithBr: React.FC<LineWithBrProps> = ({children}) => {
    return <p>
            {children.split('\n').map((line, index) =>
                    <span key={index}>{line}<br/></span>
                )}
        </p>;
}