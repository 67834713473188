import NewsletterHandler from "../../components/newsletter-handler";
import "./Accueil.scss";
import ContactForm from "../../components/contact-form";
import ContactMap from "../../components/contact-map";
import Newsletter from "../../components/newsletter-info";
import React from "react";
import MiseEnAvant from "../../components/mise-en-avant-info";
import LastSpectacles from "../../components/last-spectacles";

export const Accueil = () => {
  return (
    <div className={"accueil-container"}>
      <h1 className={"title"}>Ne ratez pas nos dernières annonces</h1>
      <NewsletterHandler/>
      <MiseEnAvant/>
      <LastSpectacles/>
      <ContactForm/>
      <ContactMap/>

    </div>
  );
};

export default Accueil;