import React from 'react';
import './LastSpectacles.scss';
import useFetch from "../../hooks/useFecth";
import {Spectacle} from "../../models/Spectacle";
import Loader from "../loader";
import Card from "../card";
import {useNavigate} from "react-router-dom";

export const LastSpectacles = () => {

  const { data, loading, error } = useFetch<Spectacle>('/spectacles/last/3');

  const navigate = useNavigate();

  function handleClick(spectacle: Spectacle) {
    navigate(`/spectacles/${spectacle.id}`)
  }

  return (
    <>
      {loading && <Loader/>}
      {error && <p>Erreur lors du chargement des données</p>}
      {data &&

        <div className={"spectacle-catalogue-container"}>
          <h1 className="title">Nos dernières créations</h1>

          <div className={"spectacle-container"}>
            {data.map((spectacle: Spectacle) => (
              <Card
                id={spectacle.id}
                title={spectacle.titre}
                image={spectacle.photo}
                description={spectacle.descriptionCourte}
                dateCreation={spectacle.dateCreation}
                imageContentType={"image/jpeg"}
                path={"/spectacles/"}
              />
            ))}
            {data.map((_: Spectacle) => (
              <div></div>
            ))}
          </div>


        </div>
      }
    </>
  );
};