import axios from "axios";

const instance = axios.create({
  baseURL: "https://www.allomamanbobo.org:8443/pcmail",
  timeout: 5000,
});

instance.defaults.headers.post["Content-Type"] = "application/json";

export default class AxiosService {
  static postMessage(message) {
    return instance
      .post("/mail", message)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }
}