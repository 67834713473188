import React, {useEffect} from 'react';
import './ArtisteDetail.scss';
import {useNavigate, useParams} from "react-router-dom";
import useFecthSolo from "../../hooks/useFecthSolo";
import Artiste from "../../models/Artiste";
import Loader from "../../components/loader";
import boutonDart from "../../models/BoutonDart";
import LineWithBr from "../../components/line_with_br";

export const ArtisteDetail = () => {

    const { id } = useParams();
    const { data, loading, error } = useFecthSolo<Artiste>('/artistes/' + id);

    const navigate = useNavigate();

    function goTo(destination: string) {
        navigate(destination);
    }

    return (
        <>
            <div className={"artiste-container-detail"}>
                {loading && <Loader />}
                {error && <p>Erreur lors du chargement des données</p>}
                {data &&
                <>
                    <div className={"artiste-container-flex"}>
                        <div className={"artiste-image-container"}>
                            <img src={`data:${data.photoContentType};base64,${data.photo}`} alt="" className={"artiste-image"}/>
                        </div>
                        <div className={"artiste-info"}>
                            <h1>{data.prenom} {data.nom}</h1>
                            <h2>{data.titre}</h2>
                            <LineWithBr>{data.biographie}</LineWithBr>
                            <div className={"section-container"}>
                                {data.spectacles && data.spectacles.length > 0 && <section className={"artiste-spectacles"}>
                                    <h3>Ses spectacles</h3>
                                    {data.spectacles.map((spectacle) => (
                                      <div key={"spectacle-"+spectacle.id}>
                                          <p className={"text-link"} onClick={() => {goTo("/spectacles/"+spectacle.id)}}>{spectacle.titre}</p>
                                      </div>
                                    ))}
                                </section>}
                                {data.ateliers && data.ateliers.length > 0 && <section className={"artiste-ateliers"}>
                                    <h3>Ses ateliers</h3>
                                    {data.ateliers.map((atelier) => (
                                      <div key={"atelier-"+atelier.id}>
                                          <p className={"text-link"} onClick={() => {goTo("/ateliers/"+atelier.id)}}>{atelier.titre}</p>
                                      </div>
                                    ))}
                                </section>}
                                {data.artEtSoins && data.artEtSoins.length > 0 && <section className={"artiste-artetsoin"}>
                                    <h3>Art et soin</h3>
                                    {data.artEtSoins.map((artetsoin) => (
                                      <div key={"artetsoin-"+artetsoin.id}>
                                          <p className={"text-link"} onClick={() => {goTo("/artetsoin/"+artetsoin.id)}}>{artetsoin.titre}</p>
                                      </div>
                                    ))}
                                </section>}
                                {data.ambsengages && data.ambsengages.length > 0 && <section className={"artiste-sengage"}>
                                    <h3>Ses engagements</h3>
                                    {data.ambsengages.map((ambsengage) => (
                                      <div key={"ambs-"+ambsengage.id}>
                                          <p className={"text-link"} onClick={() => {goTo("/ambsengage/"+ambsengage.id)}}>{ambsengage.titre}</p>
                                      </div>
                                    ))}
                                </section>}

                                {data.boutonDArts && data.boutonDArts.length > 0 && <section className={"artiste-boutons"}>
                                    <h3>Participations Bouton d'Art</h3>
                                    {data.boutonDArts.map((boutonDart) => (
                                        <div key={"ba-"+boutonDart.id}>
                                            <p className={"text-link"} onClick={() => {goTo("/boutondart/"+boutonDart.id)}}>{boutonDart.titre}</p>
                                        </div>
                                    ))}
                                </section>}
                            </div>
                        </div>
                    </div>
                </>
                }
            </div>
        </>
    );
};