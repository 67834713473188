import React from 'react';
import './MiseEnAvantInfo.scss';
import useFecthSolo from "../../hooks/useFecthSolo";
import MiseEnAvant from "../../models/MiseEnAvant";

export const MiseEnAvantInfo = () => {

  const { data, loading, error } = useFecthSolo<MiseEnAvant>('/mise-en-avants/');

  return (
    <>
      {data &&
        <div className="mise-en-avants">
          <h1 className="title">Mis En Avant</h1>
          {data.url ? (
            <a href={data.url} target="_blank" rel="noopener noreferrer">
              <img src={`data:${data.imageContentType};base64,${data.image}`} alt="" className="newsletter-image"/>
            </a>
          ) : (
            <img src={`data:${data.imageContentType};base64,${data.image}`} alt="" className="newsletter-image"/>
          )}        </div>
      }
    </>
  );
};