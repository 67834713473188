import useFetch from "../../hooks/useFecth";
import {useNavigate} from "react-router-dom";
import Loader from "../../components/loader";
import {BaCard} from "../../components/ba-card/BaCard";
import React from "react";
import ArtEtSoin from "../../models/ArtEtSoin";

export const ArtEtSoinPage = () => {
  const { data, loading, error } = useFetch<ArtEtSoin>('/art-et-soin');
  const navigate = useNavigate();

  function handleClick(artEtSoin: ArtEtSoin) {
    navigate(`/art-et-soin/${artEtSoin.id}`)
  }

  return (
    <>
      {loading && <Loader/>}
      {error && <p>Erreur lors du chargement des données</p>}
      {data &&

        <div className={"artetsoin-catalogue-container"}>
          <h1 className={"title"}>Art et Soin</h1>

          <div className="information">
            <p>Depuis sa création en 1995, la compagnie Allo Maman Bobo a choisi de s'investir avec vigueur auprès des publics éloignés de la culture, quel qu'en soit le motif. Ainsi, l'association Allo Maman Bobo est en mesure d'intervenir en milieu carcéral, zones urbaines sensibles et prioritaires et notamment hospitalier. C'est pour cela qu'elle construit avec les différents publics des espaces d'expressions, d'échanges, de créations, d'explorations... toujours dans l'espoir de tisser un lien avec l'autre. Miroir de notre époque, les différentes formes artistiques sont source d'enseignement.
              <br/>
              <br/>
              Henri Gougaud a écrit "L'importance d'une parole se mesure à la place qu'elle prendre durablement en chacun de nous, à ce qu'elle fait bouger en nous, à la terre intime qu'elle remue et fertilise."
              <br/>
              <br/>
              Allo Maman Bobo intervient depuis de nombreuses années à l'hôpital Clocheville de Tours et au CHU d'Orléans.</p>
          </div>

          <div className={"artetsoin-container"}>
            {data.map((artEtSoin: ArtEtSoin) => (
              <BaCard
                key={artEtSoin.id}
                title={artEtSoin.titre}
                image={artEtSoin.logoImage}
                onClick={() => handleClick(artEtSoin)}
              />
            ))}
            {data.map((_: ArtEtSoin) => (
              <div></div>
            ))}
          </div>
        </div>
      }
    </>
  );
};