import React from 'react';
import './YoutubeVideo.scss';

function YoutubeVideo({url}: { url: string }) {
    function urlToId(url: string) {
        if (url.includes("v=")) {
            return url.split('v=')[1];
        }
        return url
    }

    return (
        <div className="video-responsive">
            <iframe
                width="560"
                height="315"
                src={"https://www.youtube.com/embed/" + urlToId(url)}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
}

export default YoutubeVideo;
