import React from "react";
import "./ContactMap.scss"

export const ContactMap = () => {
  return (
    <section className="tp-contact-map-section">
      <div className="tp-contact-map">
        <iframe
          src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=108%20Rue%20de%20Bourgogne,%2045000,%20Orl%C3%A9ans+(Allo%20Maman%20Bobo)&amp;t=k&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          loading="lazy"
        ></iframe>
      </div>
    </section>
  );
};