import React, {useEffect, useState} from 'react';
import './NewsletterHandler.scss';
import Slider from "react-slick";
import NewsletterInfo from "../newsletter-info";
import useFetch from "../../hooks/useFecth";
import Loader from "../loader";
import Newsletter from "../../models/Newsletter";


export const NewsletterHandler = () => {
  const baseUrl = 'https://www.allomamanbobo.org:8443/amb/amb';

  const [newsletters, setNewsletters] = useState<Newsletter[]>([]);
  const [loadingNewsletters, setLoadingNewsletters] = useState(true);
  const [errorNewsletters, setErrorNewsletters] = useState<string | null>(null);

  const { data: ids, loading, error } = useFetch<number[]>('/newsletters/');

  const settings = {
    accessibility: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 2500,
    swipeToSlide: true,
    pauseOnHover: true,
    fade: false,
  };

  useEffect(() => {
    if (!ids || ids.length === 0) return;

    const fetchAllNewsletters = async () => {
      setLoadingNewsletters(true);
      setErrorNewsletters(null);

      try {
        const fetchedNewsletters = await Promise.all(
            ids.map(async (id) => {
              const response = await fetch(baseUrl + `/newsletters/${id}`);
              if (!response.ok) {
                throw new Error(`Erreur lors du chargement de l'ID ${id}`);
              }
              const data = await response.json();
              return data as Newsletter;
            })
        );

        // Trier les newsletters par date de création
        const sortedNewsletters = fetchedNewsletters.sort((a, b) =>
            new Date(a.dateCreation).getTime() - new Date(b.dateCreation).getTime()
        );

        setNewsletters(sortedNewsletters);
      } catch (err: any) {
        setErrorNewsletters(err.message || 'Erreur lors du chargement des newsletters');
      } finally {
        setLoadingNewsletters(false);
      }
    };

    fetchAllNewsletters();
  }, [ids]);

  if (loading || loadingNewsletters) return <Loader />;
  if (error || errorNewsletters) return <p>Erreur lors du chargement des données</p>;

  return (
      <div className="newsletter-handler">
        <Slider {...settings}>
          {newsletters.map((newsletter: Newsletter) => (
              <NewsletterInfo key={newsletter.id} id={newsletter.id} />
          ))}
        </Slider>
      </div>
  );
};