import { useState, useEffect } from 'react';

interface FetchState<T> {
  data: T| null;
  loading: boolean;
  error: Error | null;
}

const useFetch = <T>(url: string): FetchState<T> => {
  //const baseUrl = 'http://localhost:8080/amb';
  const baseUrl = 'https://www.allomamanbobo.org:8443/amb/amb';
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(baseUrl + url);

        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des données");
        }

        const result: T = await response.json();
        setData(result);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { data, loading, error };
};

export default useFetch;
