  import "./Card.scss"
  import React from "react";
  import {useNavigate} from "react-router-dom";

  type CardProps = {
      id: number;
      title: string;
      description: string;
      image: Uint8Array;
      imageContentType: string;
      dateCreation: string;
      path: string;
  }

  export const Card = ({id, title, description, image, imageContentType, dateCreation, path} : CardProps) => {
    const navigate = useNavigate();

    const handleNavigation = () => {
      navigate(path + id);
    }

    let dateCreationFormatted = new Date(dateCreation);

    return (
      <div key={"spectacle-"+id}className="card-container">
      <article className="cardSpectacles">
        {
          dateCreation && dateCreationFormatted.getTime() > Date.now()
            ? <div className="new-banner red">En cours de création</div>
            : null
        }
        {
          dateCreation && dateCreationFormatted.getTime() <= Date.now() && dateCreationFormatted.getTime() > (Date.now() - 1000 * 60 * 60 * 24 * 30 * 6)
            ? <div className="new-banner">Création {dateCreationFormatted.getFullYear()}</div>
            : null
        }
        <img
          className="card__background"
          src={`data:${imageContentType};base64,${image}`}
          width="1920"
          height="2193"
        />
        <div className="card__content | flow">
          <div className="card__content--container | flow">
            <h2 className="card__title">{title}</h2>
            <p className="card__description">
              {description}
            </p>
          </div>
          <button className="card__button" onClick={() => handleNavigation()}>Découvrir</button>
        </div>

      </article>


      </div>
    );
  };