import useFetch from "../../hooks/useFecth";
import './LaPresseEnParle.scss';
import PresseEnParle from "../../models/PresseEnParle";
import Loader from "../../components/loader";
import YoutubeVideo from "../../components/youtube-video";
import React from "react";

export const LaPresseEnParle = () => {
  const { data, loading, error } = useFetch<PresseEnParle>('/presse');

  function formatDate(date: string) {
    const dateObject = new Date(date);
    return dateObject.toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  return (
    <div className={"lpep-container"}>
      <h1 className={"title"}>La Presse En Parle</h1>
      <section key={"video-lpep"} className={"presse-video presse-container"}>
        <YoutubeVideo url="https://www.youtube.com/watch?v=--jb33kNoC4"/>
        <p className={"presse-description mt-12"}>Reportage France 3 "En attendant les loups" - 03 novembre 2023</p>
      </section>
      {loading && <Loader/>}
      {error && <p>Erreur lors du chargement des données</p>}
      {data && data.map((presse) => (
        <section key={presse.id} className={"presse-container"}>
          <img src={`data:${presse.imageContentType};base64,${presse.image}`} alt="" />
          <p className={"presse-description"}>{presse.description}{presse.description ? " - " : ""}{formatDate(presse.date)}</p>
        </section>
      ))}

    </div>
  );
};