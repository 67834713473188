import React from 'react';
import './GoBack.css';
import {useNavigate} from "react-router-dom";

export const GoBack = () => {

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  }

  return (
    <div className={"go-back"}>
      <div className="btn from-left" onClick={goBack}>⏎</div>
    </div>
  );
};