import React from "react";
import "./SpectacleCatalogue.scss";
import {useNavigate, useParams} from "react-router-dom";
import useFetch from "../../hooks/useFecth";
import {Spectacle} from "../../models/Spectacle";
import {BaCard} from "../../components/ba-card/BaCard";
import Loader from "../../components/loader";
import Card from "../../components/card";

export const SpectacleCatalogue = () => {

    const { name } = useParams();
    const { data, loading, error } = useFetch<Spectacle>('/spectacles/categorie/' + name);
    const navigate = useNavigate();

    const formatCategorie = (categorie: string) => {
        switch (categorie) {
            case "TOUT_PETIT":
                return "Nos spectacles jeune public et tout-petits de 0 à 6 ans";
            case "DES_QUATRES_ANS":
                return "Nos spectacles jeune public à partir de 4 ans";
            case "DES_SIX_ANS":
                return "Nos spectacles jeune public à partir de 6 ans";
            case "DES_DIX_ANS":
                return "Nos spectacles pour enfant à partir de 10 ans";
            case "EN_COURS":
                return "Nos spectacles en cours de création";
            case "AUTRES_SPECTACLES":
                return "Nos autres spectacles";
        }

    };

    function handleClick(spectacle: Spectacle) {
        navigate(`/spectacles/${spectacle.id}`)
    }

    return (
        <>
            {loading && <Loader/>}
            {error && <p>Erreur lors du chargement des données</p>}
            {data &&

              <div className={"spectacle-catalogue-container"}>
                <h1 className={"title"}>{formatCategorie(name!)}</h1>

                <div className={"spectacle-container"}>
                    {data.map((spectacle: Spectacle) => (
                        <Card
                            id={spectacle.id}
                            title={spectacle.titre}
                            image={spectacle.photo}
                            description={spectacle.descriptionCourte}
                            dateCreation={spectacle.dateCreation}
                            imageContentType={"image/jpeg"}
                            path={"/spectacles/"}
                        />
                    ))}
                    {data.map((_: Spectacle) => (
                      <>
                      <div></div><div></div>
                      </>
                    ))}
                </div>


              </div>
            }
        </>
    );
};