import React, { Component } from 'react'
import AxiosService from "../../services/service";
import './ContactForm.scss'

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    error: {}
  }


  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = ''

    this.setState({
      [e.target.name]: e.target.value,
      error
    })
  }

  subimtHandler = (e) => {
    e.preventDefault();

    const { name,
      email,
      subject,
      message,
      error } = this.state;

    if (name === '') {
      error.name = "Nom obligatoire";
    }
    if (email === '') {
      error.email = "Email obligatoire";
    }
    if (subject === '') {
      error.subject = "Objet Obligatoire";
    }


    if (error) {
      this.setState({
        error
      })
    }

    if (error.name === '' && error.email === '' && error.subject === '') {
      AxiosService.postMessage({
        name: name,
        email: email,
        numtel: "",
        message: "Sujet :" + subject + "\n Messsage :" + message
      }).then((response) => {
        console.log(response);
      }).catch((error) => {
        console.log(error);
      });

      this.setState({
        name: '',
        email: '',
        subject: '',
        message: '',
        error: {}
      })
    }


  }

  render(){
    const { name,
      email,
      subject,
      message,
      error } = this.state;

    return(
    <>
    <h1 className="title">Nous contacter</h1>

    <div className={"tp-contact-form-area"}>
      <form onSubmit={this.subimtHandler} className="form">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input value={name} onChange={this.changeHandler} type="text" name="name" placeholder="Nom Complet"/>
              <p>{error.name ? error.name : ''}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder="Courriel"/>
              <p>{error.email ? error.email : ''}</p>
            </div>
          </div>
          <div className="col-lg-12 col-md-6 col-12">
            <div className="form-field">
              <input onChange={this.changeHandler} value={subject} type="text" name="subject" placeholder="Objet"/>
              <p>{error.subject ? error.subject : ''}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-field">
              <textarea onChange={this.changeHandler} value={message} name="message" placeholder="Message"></textarea>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-submit">
              <button type="submit" className="template-btn">Envoyer le message</button>
            </div>
          </div>
        </div>
      </form>
      </div>
      </>
    )
  }
}

export default ContactForm