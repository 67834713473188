import {useNavigate, useParams} from "react-router-dom";
import useFecthSolo from "../../hooks/useFecthSolo";
import Loader from "../../components/loader";
import {GoBack} from "../../components/go-back/GoBack";
import Artiste from "../../models/Artiste";
import LineWithBr from "../../components/line_with_br";
import YoutubeVideo from "../../components/youtube-video";
import Image from "../../models/Image";
import React from "react";
// @ts-ignore
import ImageGallery from "react-image-gallery";
import ArtEtSoin from "../../models/ArtEtSoin";

export const ArtEtSoinDetail = () => {
  const { id } = useParams();
  const { data, loading, error } = useFecthSolo<ArtEtSoin>('/art-et-soin/' + id);
  const navigate = useNavigate();

  const goToArtiste = (id: number) => {
    navigate(`/artiste/${id}`)
  }

  return (
    <div className={"artetsoin-container-detail"}>
      {loading && <Loader />}
      {error && <p>Erreur lors du chargement des données</p>}
      {data &&
        <>
          <GoBack/>

          <div className={"artetsoin-container-flex"}>
            <div>
              <img src={`data:${data.logoImageContentType};base64,${data.logoImage}`} alt="" className={"artetsoin-image"}/>
            </div>
            <div className={"flex-center"}>
              <h1>{data.titre}</h1>
              <LineWithBr>{data.description}</LineWithBr>
              <h3>Les artistes présents</h3>
              {data.artistes.map((artiste: Artiste) => (
                <span className={"text-url"} onClick={() => goToArtiste(artiste.id)}>{artiste.prenom} {artiste.nom}</span>))
              }
            </div>
          </div>

          {/* eslint-disable-next-line react/jsx-no-undef */}
          {data.imageArtEtSoins.length > 0 &&
            <ImageGallery
              className={"image-gallery"}
              thumbnailPosition={"bottom"}
              showFullscreenButton={false}
              showPlayButton={false}
              slideDuration={200}
              showIndex={true}
              items={data.imageArtEtSoins.map((image: Image) => (
                {
                  original: `data:${image.imageContentType};base64,${image.image}`,
                  thumbnail: `data:${image.imageContentType};base64,${image.image}`,
                }
              ))}/>
          }
        </>
      }
    </div>
  );
};